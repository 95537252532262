import {createContext, useState} from "react";

import useToggleSpecifiedComponent from "../hooks/useToggleSpecifiedComponent";

const DropdownContext = createContext({
    isDropdownOpened: false,
    handleDropdown: () => {},
    dropdownContent: null,
    parentNodeId: null,
});
DropdownContext.displayName = "DropdownContext";

const DropdownProvider = ({children}) => {
    let {
        isOpened: isDropdownOpened,
        handle,
        dynamicContent: dropdownContent
    } = useToggleSpecifiedComponent();
    const [parentNodeId, setParentNodeId] = useState(null);

    const handleDropdown = (content, parentNodeId) => {
        handle(content);
        setParentNodeId(parentNodeId)
    }

    return (
        <DropdownContext.Provider value={{isDropdownOpened, handleDropdown, dropdownContent, parentNodeId}}>
            {children}
        </DropdownContext.Provider>
    )
}

export  { DropdownContext, DropdownProvider };