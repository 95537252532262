import React, { createContext, useContext, useState } from "react";

const CartContext = createContext();

const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartData")) || []
  );

  const addToCart = (item) => {
    const itemIndex = cartItems.findIndex((product) => product.id === item.id);
    if (itemIndex === -1) {
      const newCartItems = [...cartItems, item];
      setCartItems(newCartItems);
      localStorage.setItem("cartData", JSON.stringify(newCartItems));
    } else {
      updateCart(item)
    }
  };

  const removeItem = (itemId) => {
    const newCartItems = cartItems.filter(item => item.id !== itemId);
    setCartItems(newCartItems);
    localStorage.setItem("cartData", JSON.stringify(newCartItems));
  };

  const updateCart = (item) => {
    const itemIndex = cartItems.findIndex((product) => product.id === item.id);
    const updatedItem = { ...cartItems[itemIndex], ...item };
    const newCartItems = [...cartItems];
    newCartItems[itemIndex] = updatedItem;
    setCartItems(newCartItems);
    localStorage.setItem("cartData", JSON.stringify(newCartItems));
  }

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeItem, updateCart }}>
      {children}
    </CartContext.Provider>
  );
};

export { useCart, CartProvider };
