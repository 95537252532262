import { getUserAuth } from "../api/service/login";
import { PAGES } from "../constants";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ children }) => {
  const user = getUserAuth();
  
  if (!user) {
    return <Navigate to={PAGES.HOME.HREF} replace={true} />;
  }

  return children;
};

export default ProtectedRoutes;