import React, { createContext, useContext, useState, useEffect } from "react";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";

const LanguageContext = createContext();

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: "hy",
    debug: false,
    detection: {
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    interpolation: {
      escapeValue: false,
    },
  });

const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};

const LanguageProvider = ({ children }) => {
  const defaultLanguage = { value: "hy-AM", name: "ՀԱՅ" }; // Set your desired default language code here
  const storedLanguage = JSON.parse(localStorage.getItem("lng"));
  const initialLanguage = storedLanguage || defaultLanguage;

  const [language, setLanguage] = useState(initialLanguage);

  useEffect(() => {
    localStorage.setItem("lng", JSON.stringify(language));
  }, [language]);

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { useLanguage, LanguageProvider, LanguageContext };