import { Suspense } from "react";
import ModalRoot from "./ModalRoot";
import DropdownRoot from "./DropdownRoot";

const LayoutRoot = ({ children }) => {
  return (
    <>
      <ModalRoot />
      <DropdownRoot />
      <Suspense fallback={<div>Layout Root Loader</div>}>{children}</Suspense>
    </>
  );
};

export default LayoutRoot;
