import { useContext, lazy, Suspense } from "react";
import { DropdownContext } from "../../contexts/DropdownContext";

const Dropdown = lazy(() => import("../../common/Dropdown/Dropdown"));

const DropdownRoot = () => {
  const { isDropdownOpened, handleDropdown, dropdownContent, parentNodeId } = useContext(DropdownContext);

  return (
    <>
      {isDropdownOpened && (
        <Suspense>
          <Dropdown onClose={handleDropdown} parentRef={parentNodeId}>
            {dropdownContent}
          </Dropdown>
        </Suspense>
      )}
    </>
  );
};

export default DropdownRoot;
