import React from "react";
import ReactDOM from "react-dom/client";

import { DropdownProvider } from "./contexts/DropdownContext";
import { CartProvider } from "./contexts/CartContext";

import App from "./app/App.jsx";
import { LikedProvider } from "./contexts/LikedContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { ModalProvider } from "./contexts/ModalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <LanguageProvider>
    <CartProvider>
      <LikedProvider>
        <ModalProvider>
          <DropdownProvider>
            <App />
          </DropdownProvider>
        </ModalProvider>
      </LikedProvider>
    </CartProvider>
  </LanguageProvider>
);
