export const PAGES = {
  HOME:              { TITLE: null,                      HREF: "/" },

  LIQUID_ASSORTMENT: { TITLE: "Լիկվիդացիոն տեսականի",    HREF: "/liquidation_assortment",},
  NOVELTY:           { TITLE: "Նորույթ",                 HREF: "/novelty"},
  SOON:              { TITLE: "Շուտով",                  HREF: "/coming_soon" },
  ABOUT_US:          { TITLE: "Մեր Մասին",               HREF: "/about_us" },
  CONTACT_US:        { TITLE: "Կապ",                     HREF: "/contact_us" },
  PRICING_POLICY:    { TITLE: "Գնային Քաղաքականություն", HREF: "/pricing_policy" },

  SHOP_PRODUCT:      { TITLE: null,                      HREF: "/product/:productName/:productId" },
  PRODUCTS:          { TITLE: null,                      HREF: "/products"},
  CHECKOUT:          { TITLE: "ՎՃԱՐՈՒՄ",                 HREF: "/checkout" },

  PERSONAL_PAGE:     { TITLE: "Անձնական էջ",             HREF: "/personal_page" },
  ORDER_DETAILS:     { TITLE: null,                      HREF: "/order_details/:order_number" },

  FAVORITES:         {TITLE: null,                       HREF: "/favorites"},
  BASKET:            {TITLE: null,                       HREF: "/basket"},
  LOGIN:             {TITLE: null,                       HREF: "/login"},

  NOT_FOUND:         { TITLE: "Page Not Found",          HREF: "/*" },

};
