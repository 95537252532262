import { useState } from "react";

const useToggleSpecifiedComponent = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [dynamicContent, setDynamicContent] = useState(null);

  const handle = (content = false) => {
    setIsOpened((prevIsOpened) => !prevIsOpened);

    if (content) {
      setDynamicContent(content);
    }
  };

  return { isOpened, handle, dynamicContent };
};

export default useToggleSpecifiedComponent;
