import AXIOS_INSTANCE from "../axios";

const URL_LOGIN = process.env.REACT_APP_API_URL + "/auth/local";
const URL_REGISTER = process.env.REACT_APP_API_URL + "/auth/local/register";

export const register = async (userData) => {
    try {
        const res = await AXIOS_INSTANCE.post(URL_REGISTER, userData);
        const userAuth = res.data;
        localStorage.setItem("userAuth", JSON.stringify(userAuth));
        return userAuth;
    } catch (err) {
        return err.message;
    }
};

export const login = async (userData) => {
  try {
    const res = await AXIOS_INSTANCE.post(URL_LOGIN, userData);
    const userAuth = res.data;
    localStorage.setItem("userAuth", JSON.stringify(userAuth));
    return res.status;
  } catch (err) {
    return err.message;
  }
};

export const logout = () => {
  localStorage.removeItem("userAuth");
};

export const getUserAuth = () => {
  const userAuthJSON = localStorage.getItem("userAuth");
  return userAuthJSON ? JSON.parse(userAuthJSON) : null;
};